import { api } from 'app/config/apiConfig';
import { WarehouseWithOccupancyStatistic } from '../model/types';

export const getWarehousesWithOccupancyStatisticApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWarehousesWithOccupancyStatistic: build.query<WarehouseWithOccupancyStatistic[], void>({
      query: () => ({
        url: '/warehouses/statistics/occupancy',
      }),
    }),
  }),
});

export const { useGetWarehousesWithOccupancyStatisticQuery } = getWarehousesWithOccupancyStatisticApi;

import React, { FC, memo, useCallback } from 'react';
import { Button } from 'shared/ui/Button';

export const FranchiseKnowledgeBaseButton: FC = memo(() => {
  const goToKnowledgeBase = useCallback(() => {
    window.open('https://rentabox24.com/pl/franchise/knowledge-base/', '_blank');
  }, []);

  return (
    <Button containerClassName="fixed left-0 bottom-0 ml-3 mb-3" onClick={goToKnowledgeBase}>
      Baza wiedzy
    </Button>
  );
});

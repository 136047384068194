import { configureStore, ReducersMapObject } from '@reduxjs/toolkit';
import { api } from 'app/config/apiConfig';
import { bookingHeaderReducer } from 'widgets/BookingHeader';
import { bookingPageReducer } from 'pages/BookingPage';
import { bookingInfoStackReducer } from 'widgets/BookingInfoStack';
import { boxSizeFilterReducer } from 'features/BoxSizeFilter';
import { warehouseMapReducer } from 'widgets/SelectWarehouseMap';
import { userReducer } from 'entities/User';
import { appReducer } from 'app/appState';
import { logInModalReducer } from 'features/LogInModal';
import { contractDetailsDrawerReducer } from 'widgets/ContractDetailsDrawer';
import { warehouseStatisticCardReducer } from 'features/WarehouseStatisticCard';
import { authFormReducer } from 'widgets/AuthForm';
import { createContractModalReducer } from 'widgets/CreateContractModal';
import { contractOptionsReducer } from 'entities/Contract';
import { sizeGuideModalReducer } from 'features/SizeGuideModal';
import { globalConfirmModalReducer } from 'app/globalConfirmModal';
import { contractPageReducer } from 'pages/ContractsPage';
import { createUserModalReducer } from 'widgets/CreateUserModal';
import { completeRegistrationModalReducer } from 'widgets/CompleteRegistrationModal';
import { StateSchema } from './types';
import { requestFormModalReducer } from 'widgets/RequestModal';
import { boxFormDrawerReducer } from 'features/BoxFormDrawer';
import { leadNoteDrawerReducer } from 'features/LeadNoteDrawer';
import { reservationDetailDrawerReducer } from 'widgets/ReservationDetailsDrawer';
import { cancelReservationModalReducer } from 'features/CancelReservationModal';
import { takeUpReservationModalReducer } from 'features/TakeUpReservationModal';
import { socketConnectionReducer } from 'app/socketConnection';
import { stepperModalReducer } from 'features/StepperModal';
import { sendOfferModalReducer } from 'widgets/SendOfferModal';
import { noteDrawerReducer } from 'features/NoteDrawer';
import { gratitudeModalReducer } from 'features/GratitudeModal';
import { exportModalReducer } from 'features/ExportModal';
import { leadFormModalReducer } from 'features/LeadFormModal';
import { lateChargeSettingFormDrawerReducer } from 'features/LateChargeSettingFormDrawer';
import { insuranceFormDrawerReducer } from 'features/InsuranceFormDrawer';
import { sizeCodeFormDrawerReducer } from 'features/SizeCodeFormDrawer';

const rootReducer: ReducersMapObject<StateSchema> = {
  bookingHeader: bookingHeaderReducer,
  bookingPage: bookingPageReducer,
  bookingInfoStack: bookingInfoStackReducer,
  boxSizeFilter: boxSizeFilterReducer,
  warehouseMap: warehouseMapReducer,
  user: userReducer,
  requestFormModal: requestFormModalReducer,
  app: appReducer,
  logInModal: logInModalReducer,
  contractDetailsDrawer: contractDetailsDrawerReducer,
  warehouseStatisticCard: warehouseStatisticCardReducer,
  boxFormDrawer: boxFormDrawerReducer,
  leadNoteDrawer: leadNoteDrawerReducer,
  reservationDetailsDrawer: reservationDetailDrawerReducer,
  cancelReservationModal: cancelReservationModalReducer,
  takeUpReservationModal: takeUpReservationModalReducer,
  authForm: authFormReducer,
  socketConnection: socketConnectionReducer,
  stepperModal: stepperModalReducer,
  createContractModal: createContractModalReducer,
  contractOptions: contractOptionsReducer,
  sendOfferModal: sendOfferModalReducer,
  sizeGuideModal: sizeGuideModalReducer,
  noteDrawer: noteDrawerReducer,
  globalConfirmModal: globalConfirmModalReducer,
  gratitudeModal: gratitudeModalReducer,
  exportModal: exportModalReducer,
  leadFormModal: leadFormModalReducer,
  contractsPage: contractPageReducer,
  lateChargeSettingFormDrawer: lateChargeSettingFormDrawerReducer,
  createUserModal: createUserModalReducer,
  completeRegistrationModal: completeRegistrationModalReducer,
  insuranceFormDrawer: insuranceFormDrawerReducer,
  sizeCodeFormDrawer: sizeCodeFormDrawerReducer,

  [api.reducerPath]: api.reducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

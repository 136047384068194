import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { FC, memo, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { HistoryAction, useGetPaginatedHistoryQuery } from 'entities/History';
import { Modal } from 'shared/ui/Modal';
import { ServerTable } from 'shared/ui/ServerTable';
import { actionIconMapper } from '../../utils/helpers/actionIconMapper';
import { actionTitleMapper } from '../../utils/helpers/actionTitleMapper';

interface HistoryModalProps {
  isOpened: boolean;
  email: string | undefined;
  onCLose: () => void;
}

export const HistoryModal: FC<HistoryModalProps> = memo((props) => {
  const { isOpened, email, onCLose } = props;

  const { t } = useAppTranslation('profile');

  const columns = useMemo<ColumnsType<HistoryAction>>(
    () => [
      {
        key: 'icon',
        render: (_, record) => {
          const icon = actionIconMapper(record.actionName);

          return icon;
        },
      },
      {
        title: t('Subject'),
        key: 'subject',
        render: (_, record) => {
          const { subject } = actionTitleMapper(record.actionName, record.actionData, t);

          return subject;
        },
      },
      {
        title: t('Action'),
        key: 'action',
        render: (_, record) => {
          const { action } = actionTitleMapper(record.actionName, record.actionData, t);

          return action;
        },
      },
      {
        title: t('Object'),
        key: 'object',
        render: (_, record) => {
          const { object } = actionTitleMapper(record.actionName, record.actionData, t);

          return object;
        },
      },
      { title: t('Date'), key: 'date', render: (_, record) => dayjs(record.updatedAt || record.createdAt).fromNow() },
    ],
    [t],
  );

  return (
    <Modal width={1200} isOpen={isOpened} onClose={onCLose}>
      <ServerTable columns={columns} fetchData={useGetPaginatedHistoryQuery} defaultFilters={{ userName: email }} rowKey="userId" />
    </Modal>
  );
});

import React, { FC, memo } from 'react';
import { useTheme } from 'app/providers/ThemeProvider';
import { Tooltip } from 'shared/ui/Tooltip';

export type BadgeColor = 'success' | 'accent' | 'warn' | 'primary' | 'primaryLight' | 'error' | 'white';

interface RowSideBadgeProps {
  title: string;
  color?: BadgeColor;
  toolTipColor?: BadgeColor;
  toolTipFontColor?: BadgeColor;
}

export const RowSideBadge: FC<RowSideBadgeProps> = memo((props) => {
  const { title, color = 'success', toolTipColor = 'accent', toolTipFontColor = 'white' } = props;

  const theme = useTheme();

  return (
    <Tooltip title={title} color={theme[toolTipColor]} fontColor={theme[toolTipFontColor]} arrow={false}>
      <div className={`absolute top-0 bottom-0 left-0 w-1.5 bg-${color} rounded-r-2xl`}></div>
    </Tooltip>
  );
});

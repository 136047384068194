import classNames from 'classnames';
import React, { FC, memo, ReactNode } from 'react';

export interface TableRow {
  label: string | ReactNode;
  value: Nullable<string | number> | undefined;
  highlighted?: boolean;
  emptyValueSymbol?: string;
}

type BulletsTableTheme = 'default' | 'clear';

interface BulletsTableProps {
  rows: TableRow[];
  theme?: BulletsTableTheme;
  heading?: string;
  className?: string;
  labelClassName?: string;
}

export const BulletsTable: FC<BulletsTableProps> = memo((props) => {
  const { rows, heading, theme = 'default', className, labelClassName = '' } = props;

  return (
    <div className={classNames('w-full', className)}>
      <div className="mb-3 text-accent font-semibold text-base">{heading}</div>
      {rows.map(({ label, value, highlighted, emptyValueSymbol = '-' }, index) => {
        const isEvenRow = (index + 1) % 2 === 0;

        const themesMap: Record<BulletsTableTheme, string> = {
          default: isEvenRow ? 'bg-secondaryLight' : 'bg-white',
          clear: 'bg-white',
        };

        return (
          <div key={index} className={`flex justify-between px-3 py-2 ${themesMap[theme]}`}>
            <div className={`${highlighted ? 'font-semibold text-primary' : 'font-normal text-primaryLight'} ${labelClassName}`}>
              {label}
            </div>
            <div className={highlighted ? 'font-semibold' : 'font-normal'}>{value ?? emptyValueSymbol}</div>
          </div>
        );
      })}
    </div>
  );
});

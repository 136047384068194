import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { City, useDeleteCityMutation, useGetCitiesQuery } from 'entities/City';
import { PageLoader } from 'features/PageLoader';
import { Table } from 'shared/ui/Table';
import { ColumnsType } from 'antd/es/table';
import { Modal } from 'shared/ui/Modal';
import { ModalMode } from 'app/types/common';
import { CityForm } from 'widgets/CityForm';
import { TableActions, TableRowActions } from 'shared/ui/Table/types';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { ReactComponent as EditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { showNotification } from 'app/providers/NotificationsProvider';

export const CitiesPage: FC = memo(() => {
  const { t } = useAppTranslation(['city', 'common']);
  const { data: cities, isLoading: isGettingCitiesLoading } = useGetCitiesQuery();
  const [deleteCity, { isLoading: isDeletionLoading }] = useDeleteCityMutation();
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<Nullable<ModalMode>>(null);
  const [selectedCity, setSelectedCity] = useState<Nullable<City>>(null);

  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const handleCloseModal = useCallback((): void => {
    setModalOpened(false);
    setModalMode(null);
    setSelectedCity(null);
  }, []);

  const handleOpenModal = useCallback((mode = ModalMode.CREATE): void => {
    setModalOpened(true);
    setModalMode(mode);
  }, []);

  const handleEditCity = useCallback(
    (city: City): void => {
      setSelectedCity(city);
      handleOpenModal(ModalMode.EDIT);
    },
    [handleOpenModal],
  );

  const handleDeleteCity = useCallback(async (): Promise<void> => {
    try {
      if (selectedCity) {
        await deleteCity(selectedCity.cityId).unwrap();

        showNotification('info', t('Success', { ns: 'common' }), t('City has been deleted successfully', { ns: 'common' }));
      }
    } catch (error: CustomAny) {
      console.log(error);
      showNotification('error', t('Error', { ns: 'common' }), t('Error when deleting city', { ns: 'common' }));
    } finally {
      closeConfirmModal();
    }
  }, [selectedCity, t, deleteCity, closeConfirmModal]);

  const columns = useMemo<ColumnsType<City>>(
    () => [
      {
        title: '#',
        key: 'index',
        render: (text, record, index) => `${index + 1}`,
        align: 'center',
        width: '5%',
      },
      {
        title: t('City'),
        key: 'name',
        align: 'center',
        width: '20%',
        render: (_, record) => record.name,
      },
      {
        title: <div className="flex px-[25px]">{t('Country')}</div>,
        key: 'country',
        width: '65%',
        align: 'start',
        render: (_, record) => <div className="flex justify-start px-[10px] font-normal">{t(record.country, { ns: 'common' })}</div>,
      },
    ],
    [t],
  );

  const tableActions: Array<TableActions<City>> = useMemo(
    () => [
      {
        name: t('Add city'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: () => {
          handleOpenModal(ModalMode.CREATE);
        },
      },
    ],
    [handleOpenModal, t],
  );

  const rowActions: Array<TableRowActions<City>> = useMemo(
    () => [
      {
        name: t('Edit city'),
        icon: <EditIcon />,
        theme: 'clear',
        description: t('Edit city'),
        onClick: (selectedCity: City) => {
          handleEditCity(selectedCity);
        },
      },
      {
        name: t('Delete city'),
        icon: <DeleteIcon />,
        theme: 'clear',
        description: t('Delete city'),
        onClick: (selectedCity: City) => {
          setSelectedCity(selectedCity);
          openConfirmModal();
        },
      },
    ],
    [handleEditCity, t, openConfirmModal],
  );

  return (
    <div className="w-full h-screen flex flex-col">
      {isGettingCitiesLoading && <PageLoader />}
      <Table
        data={cities}
        columns={columns}
        rowKey="cityId"
        search
        searchField="name"
        searchPlaceholder={t('Enter city name')}
        size="middle"
        tableActions={tableActions}
        rowActions={rowActions}
      />
      <Modal isOpen={isModalOpened} onClose={handleCloseModal}>
        <CityForm city={selectedCity} mode={modalMode} handleClose={handleCloseModal} />
      </Modal>
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Delete city')}
        description={t('Are you sure you want to delete this city?')}
        isLoading={isDeletionLoading}
        onOk={handleDeleteCity}
        onCancel={closeConfirmModal}
      />
    </div>
  );
});

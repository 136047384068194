import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useSearchParams } from 'react-router-dom';
import { ErrorTag } from 'shared/ui/ErrorTag';
import { Modal } from 'shared/ui/Modal';
import { WarningPing } from 'shared/ui/WarningPing';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { Invoice, InvoiceCard } from 'entities/Invoice';

interface UnpaidBillsNotificationProps {
  invoices: Invoice[];
}

export const UnpaidBillsNotification: FC<UnpaidBillsNotificationProps> = memo((props) => {
  const { invoices } = props;

  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get('invoiceId');

  const { t } = useAppTranslation(['contracts']);

  const [isOpened, setOpened] = useState(false);

  const { isDesktop } = useScreenBreakpoints();

  const openModal = useCallback(() => {
    setOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpened(false);
  }, []);

  useEffect(() => {
    invoiceId && openModal();
  }, [invoiceId, openModal]);

  return (
    <div className="mt-2">
      <ErrorTag onClick={openModal}>{t('You have unpaid bills. Click here to get more details')}</ErrorTag>
      <Modal isOpen={isOpened} onClose={closeModal} width={isDesktop ? 520 : undefined}>
        <div className="space-y-2.5">
          <WarningPing type="error" />
          <div className="font-semibold text-lg">{t('You have unpaid bills')}</div>
          <div className="text-primaryLight font-normal">{t('To get access to the box, please, pay the bill')}</div>
          <div className="flex flex-col gap-2 max-h-[calc(100vh-115px)] overflow-y-auto pr-3">
            {invoices.map((invoice) => (
              <InvoiceCard
                defaultOpened={invoice.invoiceId === invoiceId}
                user={invoice.user}
                invoice={invoice}
                isClosedContract={Boolean(invoice.contract?.leaveDate)}
                key={invoice.invoiceId}
              />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
});

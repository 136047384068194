import { Card, Divider, Form, Input as AntdInput } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Modal } from 'shared/ui/Modal';
import { AppLogo } from 'app/appState';
import { useLoginMutation } from 'entities/User';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { GoogleAuthButton } from 'features/GoogleAuthButton';
import { showNotification } from 'app/providers/NotificationsProvider';
import { isErrorApiResponse } from 'shared/utils/helpers/isErrorApiResponse';
import { getOpenedState } from '../model/selectors/getOpenedState';
import { logInModalActions } from '../model/slice/loginModalSlice';
import { LogInModalValues } from '../model/types';
import { Checkbox } from 'shared/ui/Checkbox';

export const LogInModal: FC = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation(['login-page', 'common']);

  const [login, { isLoading }] = useLoginMutation();

  const isOpened = useAppSelector(getOpenedState);

  const [form] = Form.useForm();

  const closeModal = useCallback((): void => {
    dispatch(logInModalActions.setOpened(false));
  }, [dispatch]);

  const handleSubmit = async (values: LogInModalValues): Promise<void> => {
    try {
      const { email, password, rememberUser } = values;

      await login({ loginData: { email, password }, remember: rememberUser }).unwrap();
      closeModal();
    } catch (error: CustomAny) {
      if (isErrorApiResponse(error)) {
        const errorMessage = Array.isArray(error.data.message)
          ? error.data.message.map((message) => t(message, { ns: 'common' }))
          : t(error.data.message, { ns: 'common' });
        showNotification('error', t('Error', { ns: 'common' }), errorMessage);
      } else {
        console.log(error);
      }
    }
  };

  const handleSuccessGoogleAuth = useCallback((): void => {
    closeModal();
  }, [closeModal]);

  const handleFailGoogleAuth = useCallback(
    (error: string): void => {
      if (isErrorApiResponse(error)) {
        showNotification('error', t('Error', { ns: 'common' }), t(error.data.message));
      } else {
        console.log(error);
      }
    },
    [t],
  );

  return (
    <Modal isOpen={isOpened} onClose={closeModal}>
      <Form name="login" initialValues={{ rememberUser: true }} form={form} autoComplete="off" onFinish={handleSubmit}>
        <Card
          className="bg-transparent border-none"
          title={
            <div className="flex justify-center items-center p-4">
              <AppLogo width={180} height="100%" />
            </div>
          }
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('Please enter your email!') },
              { type: 'email', message: t('Please enter correct email!') },
            ]}
          >
            <Input placeholder={t('Your email')} bordered />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: t('Please enter your password!') },
              { min: 8, message: t('Password must be at least 8 characters!') },
            ]}
          >
            <AntdInput.Password className="h-14" placeholder={t('Your password')} variant="outlined" size="large" />
          </Form.Item>
          <Form.Item name="rememberUser" valuePropName="checked" className="mb-0">
            <Checkbox name="rememberUser" defaultChecked>
              {t('Remember me')}
            </Checkbox>
          </Form.Item>
          <Button type="submit" containerClassName="w-full" isLoading={isLoading}>
            {t('Login')}
          </Button>
          <Divider>{t('OR')}</Divider>
          <GoogleAuthButton className="w-full" onSuccessAuth={handleSuccessGoogleAuth} onFailAuth={handleFailGoogleAuth}>
            {t('Sign in with Google')}
          </GoogleAuthButton>
        </Card>
      </Form>
    </Modal>
  );
});

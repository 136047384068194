import { api } from 'app/config/apiConfig';
import { Franchise } from '../model/types';

export const getAllFranchisesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllFranchises: builder.query<Franchise[], void>({
      query: () => ({
        url: '/franchises',
      }),
    }),
  }),
});

export const { useGetAllFranchisesQuery } = getAllFranchisesApi;

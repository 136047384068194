import { TFunction } from 'i18next';
import {
  AppealHistoryActionNames,
  BoxHistoryActionNames,
  ContractHistoryActionNames,
  HistoryActionData,
  HistoryActionName,
  LeadHistoryActionNames,
  ReservationHistoryActionNames,
  UserHistoryActionNames,
  WarehouseHistoryActionNames,
} from 'entities/History';

export const actionTitleMapper = (
  actionName: HistoryActionName,
  actionData: HistoryActionData,
  t: TFunction,
): { subject: string; action: string; object?: string } => {
  const { userName = 'N/A', boxName = 'N/A', targetName = 'N/A', warehouseName = 'N/A' } = actionData;

  const actionTitleMap: Record<HistoryActionName, { subject: string; action: string; object?: string }> = {
    [UserHistoryActionNames.login_success]: {
      subject: userName,
      action: t('authorized successfully'),
    },
    [UserHistoryActionNames.login_fail]: {
      subject: userName,
      action: t('authorization fail'),
    },
    [UserHistoryActionNames.password_change]: {
      subject: userName,
      action: t('changed password'),
    },
    [UserHistoryActionNames.new_registration]: {
      subject: userName,
      action: t('registration successfully'),
    },
    [UserHistoryActionNames.registration_fail]: {
      subject: userName,
      action: t('registration fail'),
    },
    [UserHistoryActionNames.user_updated]: {
      subject: userName,
      action: t('changed'),
      object: targetName,
    },
    [BoxHistoryActionNames.box_open]: {
      subject: userName,
      action: t('opened the box'),
      object: boxName,
    },
    [BoxHistoryActionNames.box_close]: {
      subject: userName,
      action: t('closed box'),
      object: boxName,
    },
    [BoxHistoryActionNames.box_open_attempt]: {
      subject: userName,
      action: t('attempt to open box'),
      object: boxName,
    },
    [BoxHistoryActionNames.box_open_long_time]: {
      subject: boxName,
      action: t('opened a long time'),
    },
    [BoxHistoryActionNames.box_unauthorized_access]: {
      subject: boxName,
      action: t('unauthorized access to box'),
    },
    [BoxHistoryActionNames.box_verify_state]: {
      subject: boxName,
      action: t('box verify state'),
    },
    [BoxHistoryActionNames.box_update]: {
      subject: userName,
      action: t('updated a box'),
      object: boxName,
    },
    [BoxHistoryActionNames.box_create]: {
      subject: userName,
      action: t('created a box'),
      object: boxName,
    },
    [WarehouseHistoryActionNames.door_open_attempt]: {
      subject: userName,
      action: t('attempt to open door'),
      object: warehouseName,
    },
    [WarehouseHistoryActionNames.siren_turned_on]: {
      subject: warehouseName,
      action: t('turned on siren'),
    },
    [ReservationHistoryActionNames.reservation_take_up]: {
      subject: userName,
      action: t('taken up reservation'),
      object: targetName,
    },
    [ReservationHistoryActionNames.reservation_create]: {
      subject: userName,
      action: t('reserved a box'),
      object: boxName,
    },
    [ContractHistoryActionNames.contract_create]: {
      subject: userName,
      action: t('booked a box'),
      object: boxName,
    },
    [AppealHistoryActionNames.appeal_create]: {
      subject: userName,
      action: t('created an appeal'),
    },
    [AppealHistoryActionNames.appeal_creation_fail]: {
      subject: userName,
      action: t('failed to create an appeal'),
    },
    [LeadHistoryActionNames.lead_creation_fail]: {
      subject: userName,
      action: t('failed to create a lead'),
    },
    [LeadHistoryActionNames.lead_create]: {
      subject: userName,
      action: t('created a lead'),
    },
  };

  return actionTitleMap[actionName];
};

import { useContext, useEffect } from 'react';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';
import { TableFilterContext } from '../../model/context/TableFilterContextProvider';

export const useWatchTableFilterQuery = (withQueryStringFilters: boolean): void => {
  const { parsedQuery, decode, encode } = useQueryString();

  const { filters, appliedFilters, applyQueryFilters } = useContext(TableFilterContext);

  useEffect(() => {
    const queryToFilters = (): void => {
      const decodedFilter = decode(parsedQuery);

      for (const key in decodedFilter) {
        const { value, label, title } = decodedFilter[key];

        if (!filters[key] && value && label && title) {
          applyQueryFilters({ key, value, label, title });
        }
      }
    };

    if (withQueryStringFilters) {
      queryToFilters();
    }
  }, [withQueryStringFilters]);

  useEffect(() => {
    const filtersToQuery = (): void => {
      encode(appliedFilters);
    };

    if (withQueryStringFilters) {
      filtersToQuery();
    }
  }, [withQueryStringFilters, appliedFilters, encode]);
};

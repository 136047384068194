import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppLoader } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useLoginMutation } from 'entities/User';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';
import { isErrorApiResponse } from 'shared/utils/helpers/isErrorApiResponse';
import { LoginFormValues } from '../../model/types';

export const useDemoLogin = (): void => {
  const navigate = useNavigate();
  const { t } = useAppTranslation('common');

  const [loginService, { isLoading }] = useLoginMutation();
  const { startAppLoading, stopAppLoading } = useAppLoader();

  const { decode, parsedQuery } = useQueryString();

  useEffect(() => {
    const decodedQueryString = decode(parsedQuery);

    const login = async (values: LoginFormValues): Promise<void> => {
      const { email, password, rememberUser } = values;

      try {
        await loginService({ loginData: { email, password }, remember: rememberUser }).unwrap();
        navigate(AppRoutes.MY_BOXES);
      } catch (error: CustomAny) {
        if (isErrorApiResponse(error)) {
          const errorMessage = Array.isArray(error.data.message) ? error.data.message.map((message) => t(message)) : t(error.data.message);
          showNotification('error', t('Error'), errorMessage);
        } else {
          console.log(error);
        }
      }
    };

    if ('email' in decodedQueryString && 'password' in decodedQueryString) {
      void login({ email: decodedQueryString.email.value, password: decodedQueryString.password.value, rememberUser: false });
    }
  }, []);

  useEffect(() => {
    isLoading ? startAppLoading() : stopAppLoading();
  }, [isLoading, startAppLoading, stopAppLoading]);
};

import React, { FC, memo } from 'react';
import { Contract } from 'entities/Contract';
import { Invoice, InvoiceCard } from 'entities/Invoice';
import { useAppTranslation } from 'app/config/i18Config/hooks';

interface InvoiceTabProps {
  contract: Contract;
  invoices: Invoice[];
}

export const InvoiceTab: FC<InvoiceTabProps> = memo((props) => {
  const { contract, invoices } = props;

  const { t } = useAppTranslation('contracts');

  const unpaidInvoices = invoices.filter(({ debt }) => debt !== 0);
  const paidInvoices = invoices.filter(({ debt }) => debt === 0);

  return (
    <div>
      <div className="space-y-3">
        {unpaidInvoices.map((invoice) => (
          <InvoiceCard
            key={invoice.invoiceId}
            invoice={invoice}
            deposit={contract.deposit}
            user={contract.user}
            isClosedContract={Boolean(contract.leaveDate)}
          />
        ))}
      </div>
      {Boolean(paidInvoices.length) && (
        <>
          <div className="font-semibold text-lg pt-7 pb-4">{t('History')}</div>
          <div className="space-y-3">
            {paidInvoices.map((invoice) => (
              <InvoiceCard
                key={invoice.invoiceId}
                invoice={invoice}
                deposit={contract.deposit}
                user={contract.user}
                isClosedContract={Boolean(contract.leaveDate)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
});

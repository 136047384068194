import React, { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { RequestFormModal } from 'widgets/RequestModal';
import { LogInModal } from 'features/LogInModal';
import { useInitializeApp } from 'app/appState';
import { PageLoader } from 'features/PageLoader';
import { GlobalConfirmModal } from 'app/globalConfirmModal';

// Use here global logic like modals, app initialization, etc...

export const GlobalLayout: FC = memo(() => {
  useInitializeApp();

  return (
    <PageLoader>
      <RequestFormModal />
      <LogInModal />
      <GlobalConfirmModal />
      <Outlet />
    </PageLoader>
  );
});

import React, { FC, memo } from 'react';

export type BadgeColor = 'success' | 'accent' | 'warn' | 'primary' | 'primaryLight' | 'error';

interface RowBadgeProps {
  title: string;
  color?: BadgeColor;
}

export const RowBadge: FC<RowBadgeProps> = memo((props) => {
  const { title, color = 'success' } = props;

  return <div className={`absolute top-0 left-0 text-sm text-white bg-${color} font-normal rounded-br-lg px-5 py-0.5`}>{title}</div>;
});

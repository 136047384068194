import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { Form } from 'antd';
import { Modal } from 'shared/ui/Modal';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';

import { getExportModalState } from '../model/selectors/getExportModalState';
import { exportModalActions } from '../model/slice/exportModalSlice';
import { Button } from 'shared/ui/Button';
import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import { downloadExcelFile } from '../utils/helpers/downloadExcelFile';
import { showNotification } from 'app/providers/NotificationsProvider';
import { ColumnOption } from '../model/types';

export const ExportModal: FC = memo(() => {
  const { isOpened, filters, columns, entity } = useAppSelector(getExportModalState);

  const [isLoading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const { t } = useAppTranslation('common');

  const closeModal = useCallback((): void => {
    form.resetFields();
    dispatch(exportModalActions.closeModal());
  }, [dispatch, form]);

  const columnOptions: ColumnOption[] = useMemo(() => {
    return (
      columns?.map((column) => ({
        label: column.title,
        value: column.key,
      })) || []
    );
  }, [columns]);

  const handleDownloadExcelFile = useCallback(
    async ({ columns }: { columns: string[] }) => {
      try {
        const selectedColumns = columnOptions.filter((option) => columns.includes(option.value));

        const fields = selectedColumns.map((column) => {
          return {
            title: column.label,
            key: column.value,
          };
        });

        setLoading(true);

        await downloadExcelFile(entity, filters, fields);

        showNotification('info', t('Success'), t('Downloaded!'));

        closeModal();
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when downloading data'));
      } finally {
        setLoading(false);
      }
    },
    [closeModal, columnOptions, entity, filters, t],
  );

  return (
    <Modal className="max-w-[400px]" isOpen={isOpened} onClose={closeModal}>
      <Form form={form} layout="vertical" onFinish={handleDownloadExcelFile} className="flex flex-col gap-4 py-0">
        <div className="font-semibold text-3xl">{t('Export data')}</div>
        <div>{t('Select the columns you want to export to Excel')}</div>

        <Form.Item name="columns" rules={[{ required: true, message: t('Please select at least 1 column!') }]}>
          <CheckboxGroup name="columns" options={columnOptions} className="flex flex-col gap-4" />
        </Form.Item>

        <div className="flex space-x-3 justify-end">
          <Button theme="secondary" onClick={closeModal}>
            {t('Cancel')}
          </Button>
          <Button type="submit" isLoading={isLoading}>
            {t('Download')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
});

import { SizeType } from 'antd/es/config-provider/SizeContext';
import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import { CountrySelector, usePhoneInput } from 'react-international-phone';
import { Input, InputRef } from 'antd';
import classNames from 'classnames';

import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings } from 'app/appState';
import { CountryType } from 'entities/City';

import 'react-international-phone/style.css';
import s from './index.module.scss';

interface PhoneInputProps {
  initialValue?: Nullable<string>;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  bordered?: boolean;
  requiredMark?: boolean;
  isDisabled?: boolean;
  size?: SizeType;
}

export const PhoneInput: FC<PhoneInputProps> = memo((props) => {
  const { placeholder, className, onChange, bordered = false, requiredMark, isDisabled, size = 'middle', initialValue } = props;

  const globalSettings = useAppSelector(getGlobalSettings);

  const inputRef = useRef<InputRef>(null);

  const phoneInput = usePhoneInput({
    defaultCountry: (globalSettings?.country || CountryType.CH).toLowerCase(),
    value: initialValue || undefined,
    disableDialCodePrefill: true,
    onChange: (data) => {
      onChange?.(data.phone);
    },
  });

  useEffect(() => {
    if (phoneInput.inputRef && inputRef.current?.input) {
      phoneInput.inputRef.current = inputRef.current.input;
    }
  }, [inputRef, phoneInput.inputRef]);

  const preFillDialCode = useCallback(() => {
    if (!initialValue) {
      phoneInput.setCountry(phoneInput.country.iso2);
    }
  }, [initialValue, phoneInput]);

  return (
    <>
      {requiredMark && <div className="text-error text-2xl absolute z-10 top-0 left-1 pointer-events-none">*</div>}
      <Input
        className={classNames(s.input, s[size], className)}
        variant={bordered ? 'outlined' : 'borderless'}
        addonBefore={
          <CountrySelector
            selectedCountry={phoneInput.country.iso2}
            onSelect={(country) => {
              phoneInput.setCountry(country.iso2);
            }}
            buttonContentWrapperClassName="w-10"
            buttonClassName="!border-none"
          />
        }
        placeholder={placeholder}
        onChange={phoneInput.handlePhoneValueChange}
        disabled={isDisabled}
        value={phoneInput.inputValue}
        ref={inputRef}
        onFocus={preFillDialCode}
      />
    </>
  );
});

import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Warehouse } from '../model/types';

interface Params {
  search?: string;
}

export const getPaginatedWarehousesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedWarehouses: build.query<Pagination<Warehouse>, Params>({
      query: (args) => ({
        url: '/warehouses/paginated',
        params: args,
      }),
    }),
  }),
});

export const { useGetPaginatedWarehousesQuery } = getPaginatedWarehousesApi;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogInModalSchema } from '../types';

const initialState: LogInModalSchema = {
  isOpened: false,
};

const logInModalSlice = createSlice({
  name: 'logInModal',
  initialState,
  reducers: {
    setOpened: (state: LogInModalSchema, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
  },
});

export const { actions: logInModalActions, reducer: logInModalReducer } = logInModalSlice;

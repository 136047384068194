import dayjs from 'app/config/dayjsConfig';
import React, { FC, memo, ReactElement } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { HistoryAction } from 'entities/History';
import { actionIconMapper } from '../../utils/helpers/actionIconMapper';
import { actionTitleMapper } from '../../utils/helpers/actionTitleMapper';

interface HistoryActionRowProps {
  data: HistoryAction;
}

export const HistoryActionRow: FC<HistoryActionRowProps> = memo((props) => {
  const {
    data: { actionName, actionData, createdAt, updatedAt },
  } = props;

  const { t } = useAppTranslation('profile');

  const getActionTitle = (): ReactElement => {
    const { subject, action, object } = actionTitleMapper(actionName, actionData, t);

    const layout = (): ReactElement => {
      return (
        <div className="font-normal">
          <span className="font-medium">{subject}</span> <span className="text-accent">{action}</span>{' '}
          <span className="text-accent">{object}</span>
        </div>
      );
    };

    return layout();
  };

  const lastUpdateTime = dayjs(updatedAt || createdAt).fromNow();

  const actionIcon = actionIconMapper(actionName);

  return (
    <div className="flex items-center space-x-4">
      {actionIcon}
      <div className="flex flex-col">
        {getActionTitle()}
        <div className="text-sm text-primaryLight">{lastUpdateTime}</div>
      </div>
    </div>
  );
});

import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ReactComponent as CancelIcon } from 'shared/assets/icons/CancelIcon.svg';
import { ReactComponent as ContractsTableIcon } from 'shared/assets/icons/ContractsTableIcon.svg';
import { ReactComponent as EditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ServerTable } from 'shared/ui/ServerTable';
import { ReactComponent as ContractWhiteIcon } from 'shared/assets/icons/ContractWhiteIcon.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Reservation, useGetPaginatedReservationsQuery } from 'entities/Reservation';
import { ReservationDetailsDrawer, reservationDetailDrawerActions } from 'widgets/ReservationDetailsDrawer';
import { checkExpirationDate } from 'shared/utils/helpers/checkExpirationDate';
import { CancelReservationModal, cancelReservationModalActions } from 'features/CancelReservationModal';
import { TakeUpReservationModal, takeUpReservationModalActions } from 'features/TakeUpReservationModal';
import { CreateContractModal, createContractModalActions } from 'widgets/CreateContractModal';
import { BadgeColor, RowBadge } from 'shared/ui/ServerTable/RowBadge';
import { getUserName } from 'entities/User';

export const ReservationsPage: FC = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useAppTranslation('common');

  const goToUserContracts = useCallback(
    (userId: string): void => {
      if (userId) {
        navigate(`${AppRoutes.USERS}/${userId}/contracts`);
      }
    },
    [navigate],
  );

  const openReservationDetails = useCallback(
    (reservationId: string | undefined): void => {
      if (reservationId) {
        dispatch(reservationDetailDrawerActions.setOpenReservationDetails(reservationId));
      }
    },
    [dispatch],
  );

  const openCancelReservationModal = useCallback(
    (reservation: Reservation): void => {
      dispatch(cancelReservationModalActions.openCancelReservationModal(reservation));
    },
    [dispatch],
  );

  const openTakeUpReservationModal = useCallback(
    (reservation: Reservation): void => {
      dispatch(takeUpReservationModalActions.openTakeUpReservationModal(reservation));
    },
    [dispatch],
  );

  const rowActions: Array<ServerTableRowActions<Reservation>> = useMemo(
    () => [
      {
        name: 'openBox',
        theme: 'primary',
        label: t('View details'),
        icon: <ContractWhiteIcon />,
        iconPosition: 'prev',
        onClick: (selectedReservation) => {
          openReservationDetails(selectedReservation.reservationId);
        },
      },
      {
        name: 'edit',
        label: t('Edit'),
        icon: <EditIcon />,
        onClick: (selectedReservation) => {
          console.log('selectedReservation', selectedReservation);
        },
      },
      {
        name: 'takeUp',
        label: t('Take up'),
        icon: <ContractsTableIcon />,
        onClick: (selectedReservation) => {
          openTakeUpReservationModal(selectedReservation);
        },
      },
      {
        name: 'cancel',
        label: t('Cancel Reservation'),
        icon: <CancelIcon />,
        onClick: (selectedReservation) => {
          openCancelReservationModal(selectedReservation);
        },
      },
    ],
    [openCancelReservationModal, openReservationDetails, openTakeUpReservationModal, t],
  );

  const openCreateReservationModal = useCallback((): void => {
    dispatch(createContractModalActions.setOpened(true));
  }, [dispatch]);

  const columns = useMemo<ColumnsType<Reservation>>(
    () => [
      {
        title: t('Customer'),
        key: 'userId',
        render: (_, record) => {
          const isExpiredReservation = checkExpirationDate(record.expirationDate);

          const getBadgeColor = (): BadgeColor | undefined => {
            if (record.takenUpDate) return 'accent';
            if (isExpiredReservation && !record.takenUpDate && !record.cancellationDate) return 'error';
            if (record.cancellationDate && !record.takenUpDate) return 'primaryLight';
          };

          const getBadgeTitle = (): string => {
            if (record.takenUpDate) return t('Taken up');
            if (isExpiredReservation && !record.takenUpDate && !record.cancellationDate) return t('Expired');
            if (record.cancellationDate && !record.takenUpDate) return t('Cancelled');

            return t('Active');
          };

          return (
            <div
              className="text-accent font-normal cursor-pointer underline"
              onClick={() => {
                goToUserContracts(record.user.userId);
              }}
            >
              {getUserName(record.user)}
              <RowBadge color={getBadgeColor()} title={getBadgeTitle()} />
            </div>
          );
        },
      },
      {
        title: t('Warehouse'),
        key: 'warehouseId',
        render: (_, record) => (
          <div
            className="text-accent font-normal cursor-pointer underline"
            onClick={() => {
              // goToUserContracts(record.user.userId); // TODO GO TO WAREHOUSE PAGE
            }}
          >
            {record.warehouse.name}
          </div>
        ),
      },
      {
        title: t('Reservation number'),
        key: 'reservationNumber',
        width: '5%',
        render: (_, record) => (
          <div
            className="text-accent font-normal cursor-pointer underline"
            onClick={() => {
              openReservationDetails(record.reservationId);
            }}
          >
            #{record.reservationNumber}
          </div>
        ),
      },
      {
        title: t('Square'),
        key: 'square',
        render: (_, record) => (
          <div className="text-primary">
            <div>{record.boxes[0]?.name}</div>
            <div className="text-sm font-normal">
              {record.boxes[0]?.square}m<sup>2</sup>
            </div>
          </div>
        ),
      },
      {
        title: t('Type'),
        key: 'invoiceFrequencyType',
        render: (_, record) => <div className="text-primary font-normal capitalize">{record.invoiceFrequencyType}</div>,
      },
      {
        title: t('Expiry date'),
        key: 'expirationDate',
        render: (_, record) => {
          const isExpiredReservation = checkExpirationDate(record.expirationDate);

          return (
            <div className={isExpiredReservation ? 'text-error font-semibold' : 'text-primary font-normal'}>
              {getClientDateFormat(record.expirationDate)}
            </div>
          );
        },
      },
      {
        title: t('Date of creation'),
        key: 'createdAt',
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.createdAt)}</div>,
      },
      {
        title: (
          <div className="flex flex-col">
            <span>{t('Start date')}</span>
            <span>{t('End date')}</span>
          </div>
        ),
        key: 'startRentDate',
        render: (_, record) => (
          <div className="flex flex-col">
            <div className="text-primary">{getClientDateFormat(record.startRentDate)}</div>
            <div className="text-primary font-normal text-sm">{getClientDateFormat(record.endRentDate)}</div>
          </div>
        ),
      },
    ],
    [t, goToUserContracts, openReservationDetails],
  );

  const tableActions: Array<ServerTableActions<Reservation>> = useMemo(
    () => [
      {
        name: t('Create Reservation'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: openCreateReservationModal,
      },
      {
        name: t('Filters'),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          console.log('onClick');
        },
      },
    ],
    [openCreateReservationModal, t],
  );

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Reservations')}</div>
      <ServerTable
        columns={columns}
        rowActions={rowActions}
        fetchData={useGetPaginatedReservationsQuery}
        defaultFilters={{
          enableExpired: true,
          enableCancelled: true,
          enableTakenUp: true,
          orderBy: 'createdAt:DESC',
        }}
        rowKey="reservationId"
        search
        tableActions={tableActions}
        tableActionsPosition="prev"
        searchPlaceholder={t('Enter reservation number')}
        rowActionsLimit={1}
      />
      <ReservationDetailsDrawer />
      <CancelReservationModal />
      <TakeUpReservationModal />
      <CreateContractModal />
    </div>
  );
});

import React, { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { MainHeader } from 'widgets/MainHeader';
import { useAdminRole } from 'entities/User';
import { FranchiseKnowledgeBaseButton } from 'widgets/FranchiseKnowledgeBaseButton';

export const MainLayout: FC = memo(() => {
  const isAdmin = useAdminRole();

  return (
    <div>
      <div className="wrapper">
        <MainHeader />
        <main className="mb-16">
          <Outlet />
        </main>
        {isAdmin && <FranchiseKnowledgeBaseButton />}
      </div>
    </div>
  );
});

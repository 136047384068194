import { ColumnsType } from 'antd/es/table';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as KeyIcon } from 'shared/assets/icons/KeyIcon.svg';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ReactComponent as ContractsTableIcon } from 'shared/assets/icons/ContractsTableIcon.svg';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ServerTable } from 'shared/ui/ServerTable';
import { ReactComponent as BoxInfoIcon } from 'shared/assets/icons/BoxInfoIcon.svg';
import { ReactComponent as BoxEditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { ReactComponent as LockIcon } from 'shared/assets/icons/LockIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import {
  BoxForListing as Box,
  useActivateBoxMutation,
  useDeactivateBoxMutation,
  useGetPaginatedBoxListingQuery,
  LockStatusMarker,
  useOnLockStatusChanged,
} from 'entities/Box';
import { useGetCurrencySymbol } from 'app/appState';
import { getClientDateFormat, getClientDateWithTimeFormat } from 'shared/utils/helpers/getDateFormat';
import { Toggle } from 'shared/ui/Toggle';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useOpenBox } from 'features/OpenBox';
import { useTableFilterContext } from 'features/TableFilter';
import { transformBoxesListFilters } from '../utils/transformBoxesListFilters';
import { BoxesListFilter } from './BoxesListFilter';
import { BoxInfoCard } from './BoxInfoCard';
import { ContractDetailsDrawer, contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { ModalMode } from '../model/types';
import { BoxFormDrawer, DrawerMode, boxFormDrawerActions } from 'features/BoxFormDrawer';
import { Paragraph } from 'shared/ui/Paragraph';
import { ReservationDetailsDrawer, reservationDetailDrawerActions } from 'widgets/ReservationDetailsDrawer';
import { CancelReservationModal } from 'features/CancelReservationModal';
import { TakeUpReservationModal } from 'features/TakeUpReservationModal';
import { ExportEntity, ExportModal, getBoxColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';

export const BoxesTable: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'boxes', 'common']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const [modalMode, setModalMode] = useState<Nullable<ModalMode>>(null);
  const [selectedBox, setSelectedBox] = useState<Nullable<Box>>(null);

  const { openExportModal } = useOpenExportModal();

  const [activateBox] = useActivateBoxMutation();
  const [deactivateBox] = useDeactivateBoxMutation();

  const { executeBoxOpening } = useOpenBox();

  const newLockStatus = useOnLockStatusChanged();

  const goToUserContracts = useCallback(
    (userId: string | undefined): void => {
      if (userId) {
        navigate(`${AppRoutes.USERS}/${userId}/contracts`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (newLockStatus) {
      showNotification(
        'warning',
        t('Lock status change'),
        t('Lock {{lockId}} has change status to {{status}}', { lockId: newLockStatus.lockId, status: newLockStatus.status }),
      );
    }
  }, [newLockStatus, t]);

  useEffect(() => {
    // TO DO INVESTIGATE HOW TO CLEAR ALL FILTERS WITHOUT USE EFFECT !!!
    return () => {
      clearAllFilters();
    };
  }, []);

  const currencySymbol = useGetCurrencySymbol();

  const closeModal = useCallback((): void => {
    setModalMode(null);
    setSelectedBox(null);
  }, []);

  const openModal = useCallback((mode: ModalMode): void => {
    setModalMode(mode);
  }, []);

  const openBoxInfoModal = useCallback(
    (box: Box, mode = ModalMode.BOX_INFO): void => {
      setSelectedBox(box);
      openModal(mode);
    },
    [openModal],
  );

  const createBox = useCallback((): void => {
    dispatch(boxFormDrawerActions.setOpenBoxForm({ mode: DrawerMode.BOX_CREATE }));
  }, [dispatch]);

  const editBox = useCallback(
    (box: Box): void => {
      dispatch(boxFormDrawerActions.setOpenBoxForm({ mode: DrawerMode.BOX_EDIT, box }));
    },
    [dispatch],
  );

  const openBox = useCallback(
    (box: Box): void => {
      executeBoxOpening({
        box,
        openByAdmin: true,
        isExternalBox: box.timeToOpenLockMS != null,
      });
    },
    [executeBoxOpening],
  );

  const toggleBoxActivation = useCallback(
    async (box: Box) => {
      try {
        if (box.isActive) {
          await deactivateBox(box.boxId).unwrap(); // TODO ADD CONFIRM + ADD TOGGLE BOX ACTIVATION API
          showNotification('info', t('Success'), t('Box has been successfully deactivated'));
        } else {
          await activateBox(box.boxId).unwrap();
          showNotification('info', t('Success'), t('Box has been successfully activated'));
        }
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), 'Error when changing box status');
      }
    },
    [activateBox, deactivateBox, t],
  );

  const openContractDetails = useCallback(
    (contractId: string | undefined): void => {
      if (contractId) {
        dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
      }
    },
    [dispatch],
  );

  const openReservationDetails = useCallback(
    (reservationId: string | undefined): void => {
      if (reservationId) {
        dispatch(reservationDetailDrawerActions.setOpenReservationDetails(reservationId));
      }
    },
    [dispatch],
  );

  const rowActions: Array<ServerTableRowActions<Box>> = useMemo(
    () => [
      {
        name: 'boxHistory',
        icon: <BoxInfoIcon />,
        theme: 'clear',
        description: t('Box history', { ns: 'boxes' }),
        onClick: (selectedUser) => {
          console.log(selectedUser);
        },
      },
      {
        name: 'editBox',
        icon: <BoxEditIcon />,
        theme: 'clear',
        description: t('Edit box'),
        onClick: editBox,
      },
      {
        name: 'deleteBox',
        icon: <DeleteIcon />,
        theme: 'clear',
        description: t('Delete box'),
        onClick: (selectedBox) => {
          console.log(selectedBox);
        },
      },
      {
        name: 'openBox',
        theme: 'primary',
        label: t('Open'),
        icon: <KeyIcon className="stroke-white" />,
        iconPosition: 'prev',
        onClick: openBox,
      },
    ],
    [editBox, openBox, t],
  );

  const columns = useMemo<ColumnsType<Box>>(
    () => [
      {
        title: t('Box'),
        width: '10%',
        key: 'name',
        render: (_, record) => (
          <div>
            <div
              className="text-accent font-semibold underline cursor-pointer"
              onClick={() => {
                openBoxInfoModal(record);
              }}
            >
              {record.name}
            </div>
            {record.lockAddress && (
              <div className="flex items-center py-2">
                <LockIcon className="w-4 h-4 mr-1 stroke-accent" />
                <span className="text-primary text-xs">{record.lockAddress}</span>
              </div>
            )}
            <LockStatusMarker lockStatus={record.lockStatus} isExternalBox={record.timeToOpenLockMS != null} />
          </div>
        ),
      },
      {
        title: t('Square'),
        width: '10%',
        key: 'square',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <div className="flex flex-col">
            <div className="text-sm">{record.warehouseName}</div>
            <div className="font-bold">
              {record.square}m<sup className="font-bold">2</sup>{' '}
            </div>
          </div>
        ),
      },
      {
        title: t('Cost'),
        width: '15%',
        key: 'monthRate',
        render: (_, record) => (
          <div className="text-primary">
            {record.dailyRate && (
              <div>
                {record.dailyRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Day')}</span>
              </div>
            )}
            {record.weekRate && (
              <div>
                {record.weekRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Week')}</span>
              </div>
            )}
            {record.monthRate && (
              <div>
                {record.monthRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Month')}</span>
              </div>
            )}
          </div>
        ),
      },
      {
        title: t('Customer'),
        key: 'user',
        width: '15%',
        render: (_, record) => (
          <div>
            <div className="flex gap-2 text-primary font-normal">
              {record?.userId && (
                <div>
                  <div className="flex gap-2 text-primary font-normal">
                    {record.userEmail}
                    <ContractsTableIcon
                      className="cursor-pointer"
                      onClick={() => {
                        goToUserContracts(record.userId);
                      }}
                    />
                  </div>
                  {record?.contractId && (
                    <div className="text-primary font-normal">
                      {t('Contract')}{' '}
                      <span
                        className="text-accent underline cursor-pointer"
                        onClick={() => {
                          openContractDetails(record.contractId);
                        }}
                      >
                        {record.contractNumber}
                      </span>
                    </div>
                  )}
                  {record.reservationId && (
                    <div className="text-primary font-normal">
                      {t('Reservation')}{' '}
                      <span
                        className="text-accent underline cursor-pointer"
                        onClick={() => {
                          openReservationDetails(record.reservationId);
                        }}
                      >
                        {record.reservationNumber}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        title: t('Last opened'),
        key: 'lastOpened',
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{getClientDateWithTimeFormat(record.lastOpened)}</div>,
      },
      {
        title: t('Renting info'),
        key: 'rentingInfo',
        width: '20%',
        render: (_, record) => (
          <>
            {record.contractId && (
              <>
                {record.isContractPaid ? (
                  <div className="text-primary font-normal">
                    {t('Charged to')}: {getClientDateFormat(record.chargedTo)}
                    <div className="text-success">{t('Contract is paid')}</div>
                  </div>
                ) : (
                  <div className="text-primary font-normal">
                    {t('Charged to')}: {getClientDateFormat(record.chargedTo)}
                    <div className="text-error">{t('Contract is unpaid')}</div>
                  </div>
                )}
              </>
            )}
            {record.expirationDate && record.reservationId && (
              <div className="text-primary font-normal">
                {t('Expiration date')}: <span className="text-warn font-normal">{getClientDateFormat(record.expirationDate)}</span>
              </div>
            )}
          </>
        ),
      },
      {
        title: t('Availability'),
        key: 'availability',
        width: '7%',
        render: (_, record) => (
          <div>
            <Toggle
              checked={record.isActive}
              onChange={async () => {
                await toggleBoxActivation(record);
              }}
            />
            <div className="text-xs text-primaryLight mt-2">{t(record.isActive ? 'Enabled' : 'Disabled')}</div>
          </div>
        ),
      },
      {
        title: t('Note'),
        key: 'note',
        width: '15%',
        render: (_, record) => (
          <Paragraph rows={2} className="text-primaryLight font-normal">
            {record.note}
          </Paragraph>
        ),
      },
    ],
    [t, openBoxInfoModal, currencySymbol, goToUserContracts, openContractDetails, openReservationDetails, toggleBoxActivation],
  );

  const tableActions: Array<ServerTableActions<Box>> = useMemo(
    () => [
      {
        name: t('Add Box'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: createBox,
      },
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformBoxesListFilters(appliedFilters),
            columns: getBoxColumnKeys(t),
            entity: ExportEntity.BOX,
          });
        },
      },
      {
        name: t('Filters', { ns: 'boxes' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [t, createBox, setFiltersOpened, openExportModal, appliedFilters],
  );

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Boxes List')}</div>
      <ServerTable
        columns={columns}
        rowActions={rowActions}
        fetchData={useGetPaginatedBoxListingQuery}
        defaultFilters={transformBoxesListFilters(appliedFilters)}
        rowKey="boxId"
        search
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        tableActions={tableActions}
        tableActionsPosition="prev"
        searchPlaceholder={t('Enter box, customer info or lock number')}
      />
      <BoxInfoCard isOpen={modalMode === ModalMode.BOX_INFO} onClose={closeModal} box={selectedBox} />
      <ContractDetailsDrawer />
      <ReservationDetailsDrawer />
      <BoxFormDrawer />
      <CancelReservationModal />
      <TakeUpReservationModal />
      <ExportModal />
      <BoxesListFilter />
    </div>
  );
});

import { api } from 'app/config/apiConfig';
import { Warehouse } from '../model/types';

export const getAllWarehousesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllWarehouses: build.query<Warehouse[], void>({
      query: () => ({
        url: '/warehouses',
      }),
    }),
  }),
});

export const { useGetAllWarehousesQuery } = getAllWarehousesApi;

import { Drawer } from 'antd';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { WarehouseForBooking } from 'entities/Warehouse';
import { Map, Marker } from 'features/Map';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { GeolocationCoords } from 'app/types/common';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { ReactComponent as DocumentIcon } from 'shared/assets/icons/DocumentIcon.svg';
import { ReactComponent as MapIcon } from 'shared/assets/icons/MapIcon.svg';
import { warehouseMapActions } from '../model/slice/warehouseMapSlice';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/CloseIcon.svg';
import s from './SelectWarehouseMapMobile.module.scss';
import { ClippedWarehouseCard } from './WarehouseCard/ClippedWarehouseCard';
import { WarehousesList } from './WarehousesList';

interface SelectWarehouseMapMobileProps {
  onCompleteStep: (warehouse: WarehouseForBooking) => void;
  markers: Marker[];
  center: GeolocationCoords;
  selectedMarkerId: Nullable<string>;
  selectMarker: (lat: number, lng: number, markerId: string) => void;
  directionResult: Nullable<google.maps.DirectionsResult>;
  onSelectMarker: (warehouseCoords: GeolocationCoords) => void;
  onGetRoute: (destination: GeolocationCoords, warehouseId: string) => Promise<void>;
  onClearRoute: () => void;
  selectedWarehouseData: WarehouseForBooking | undefined;
  warehouses: WarehouseForBooking[] | undefined;
  selectedWarehouseId: Nullable<string>;
  outlinedWarehouseId: Nullable<string>;
}

export const SelectWarehouseMapMobile: FC<SelectWarehouseMapMobileProps> = memo((props) => {
  const {
    onCompleteStep,
    warehouses,
    selectMarker,
    selectedMarkerId,
    onSelectMarker,
    markers,
    directionResult,
    selectedWarehouseData,
    onClearRoute,
    onGetRoute,
    center,
    outlinedWarehouseId,
  } = props;

  const { t } = useAppTranslation('booking');

  const dispatch = useAppDispatch();

  const [mobileViewMode, setMobileViewMode] = useState<'list' | 'map'>('list');

  const toggleMobileViewMode = useCallback((): void => {
    setMobileViewMode(mobileViewMode === 'map' ? 'list' : 'map');
  }, [mobileViewMode]);

  const closeCardDrawer = useCallback((): void => {
    dispatch(warehouseMapActions.setSelectedCard(null));
  }, [dispatch]);

  const getRouteOnMapView = useCallback(
    async (destination: GeolocationCoords, warehouseId: string): Promise<void> => {
      await onGetRoute(destination, warehouseId);
      setMobileViewMode('map');
    },
    [onGetRoute],
  );

  useEffect(() => {
    const isRouteDrawn = Boolean(directionResult);

    if (isRouteDrawn) {
      closeCardDrawer();
    }
  }, [closeCardDrawer, directionResult]);

  const renderMobileView = (): JSX.Element => {
    switch (mobileViewMode) {
      case 'map':
        return (
          <>
            <Map
              mapContainerClassName="w-full h-[calc(100vh-theme(spacing.headerHeight)-34px)]"
              markers={markers}
              center={center}
              mapId="warehouseMap"
              selectedMarkerId={selectedMarkerId}
              selectMarker={selectMarker}
              directionResult={directionResult}
              onSelectMarker={onSelectMarker}
              withMarkerPopups={false}
            >
              {directionResult && (
                <Button
                  containerClassName="absolute top-2 right-1/4 -translate-x-1/2"
                  theme="secondary"
                  icon={<CloseIcon className="stroke-primaryLight" />}
                  iconPosition="prev"
                  onClick={onClearRoute}
                >
                  {t('Clear route')}
                </Button>
              )}
              {selectedWarehouseData && (
                <Drawer
                  className={s.drawer}
                  rootClassName={s.drawerContainer}
                  open={Boolean(selectedWarehouseData)}
                  mask={false}
                  placement="bottom"
                  closable={false}
                  zIndex={10}
                  height={512}
                  onClose={closeCardDrawer}
                >
                  <div className="flex flex-col">
                    <div className="bg-secondaryAccent p-1 self-end rounded-full mb-2 cursor-pointer" onClick={closeCardDrawer}>
                      <CloseIcon className="[&>path]:stroke-primary" />
                    </div>
                    <ClippedWarehouseCard
                      warehouse={selectedWarehouseData}
                      isOutlined={false}
                      onSelectCard={() => null}
                      onGetRoute={onGetRoute}
                      onClearRoute={onClearRoute}
                      onViewBoxes={() => {
                        onCompleteStep(selectedWarehouseData);
                      }}
                    />
                  </div>
                </Drawer>
              )}
            </Map>
            <Button
              containerClassName="fixed left-4 right-4 bottom-3 z-20"
              icon={<DocumentIcon className="[&>path]:stroke-white" />}
              iconPosition="prev"
              onClick={toggleMobileViewMode}
            >
              {t('Return to list')}
            </Button>
          </>
        );
      case 'list':
        return (
          <div className="relative overflow-y-auto bg-secondaryLight p-6 pb-24 w-full desktop:static desktop:hidden desktop:min-w-[545px]">
            <WarehousesList
              warehouses={warehouses}
              outlinedWarehouseId={outlinedWarehouseId}
              onSelectCard={selectMarker}
              onGetRoute={getRouteOnMapView}
              onClearRoute={onClearRoute}
              onSelectWarehouse={onCompleteStep}
            />
            <Button
              containerClassName="fixed left-4 right-4 bottom-3 z-20"
              icon={<MapIcon className="[&>path]:stroke-white" />}
              iconPosition="prev"
              onClick={toggleMobileViewMode}
            >
              {t('Show on map')}
            </Button>
          </div>
        );
    }
  };

  return <>{renderMobileView()}</>;
});

import { Input as AntdInput } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import classNames from 'classnames';
import React, { ChangeEvent, FC, memo, ReactNode } from 'react';
import s from './index.module.scss';

interface InputProps {
  value?: Nullable<string>;
  onChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  prefix?: ReactNode;
  className?: string;
  bordered?: boolean;
  size?: SizeType;
  disabled?: boolean;
  maxLength?: number;
  requiredMark?: boolean;
}

export const Input: FC<InputProps> = memo((props) => {
  const { value, placeholder, prefix, className, onChange, disabled, maxLength, bordered = false, size = 'middle', requiredMark } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange?.(e.target.value, e);
  };

  return (
    <>
      {requiredMark && <div className="text-error text-2xl absolute z-10 top-0 left-1 pointer-events-none">*</div>}
      <AntdInput
        className={classNames(s.input, s[size], className)}
        variant={bordered ? 'outlined' : 'borderless'}
        placeholder={placeholder}
        prefix={prefix}
        value={value || undefined}
        onChange={handleChange}
        disabled={disabled}
        maxLength={maxLength}
      />
    </>
  );
});

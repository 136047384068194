import { Form } from 'antd';
import { getGlobalSettings } from 'app/appState';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { Box } from 'entities/Box';
import { ContractInfo } from 'entities/Contract';
import React, { FC, memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ReactComponent as VisaIcon } from 'shared/assets/icons/VisaIcon.svg';
import { ReactComponent as MastercardIcon } from 'shared/assets/icons/MastercardIcon.svg';
import { ReactComponent as AmexIcon } from 'shared/assets/icons/AmexIcon.svg';
import { ReactComponent as GoogleIcon } from 'shared/assets/icons/GoogleIcon.svg';
import { ReactComponent as AppleIcon } from 'shared/assets/icons/AppleIcon.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox } from 'shared/ui/Checkbox';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';
import { WarehouseForBooking } from 'entities/Warehouse';
import { AppRoutes } from 'app/config/routerConfig/types';
import { Button } from 'shared/ui/Button';
import { BookingDocumentsNames } from 'app/appState/model/types';
import { BookAndPayButton } from './BookAndPayButton';
import { PaymentProcessor } from 'entities/Payment';
import { CollapseRadioButtonGroup } from 'shared/ui/CollapseRadioButtonGroup';
import { getLoggedUserData } from 'entities/User';
import { ReserveBox } from 'features/ReserveBox';
import { Trans } from 'react-i18next';
import { PriceDetailsTable } from 'features/PriceDetailsTable';
import { GratitudeModalType, useOpenGratitudeModal } from 'features/GratitudeModal';

interface PaymentFormProps {
  contractInfo: ContractInfo;
  boxInfo: Nullable<Box>;
  warehouseInfo: Nullable<WarehouseForBooking>;
  onCompleteStep: () => void;
}

export const PaymentForm: FC<PaymentFormProps> = memo((props) => {
  const { boxInfo, warehouseInfo, contractInfo, onCompleteStep } = props;

  const { t } = useAppTranslation(['booking', 'common']);
  const navigate = useNavigate();

  const { openGratitudeModal } = useOpenGratitudeModal();

  const [termsAgreement, setTermsAgreement] = useState<boolean>(false);
  const [isAutopaymentEnabled, setAutopaymentEnabled] = useState<boolean>(false);
  const [processor, setProcessor] = useState<PaymentProcessor>(PaymentProcessor.STRIPE);

  const { scrollToElement, scrollRef } = useScrollToElement('center', 500);

  useLayoutEffect(() => {
    scrollToElement();
  }, [scrollToElement]);

  useEffect(() => {
    const watchAutoPaymentStatus = (): void => {
      switch (processor) {
        case PaymentProcessor.STRIPE:
          setAutopaymentEnabled(false);
          break;
        case PaymentProcessor.PAYPAL:
          setAutopaymentEnabled(false);
          break;
        case PaymentProcessor.SEPA:
          setAutopaymentEnabled(true);
          break;
        case PaymentProcessor.BANK_TRANSFER:
          setAutopaymentEnabled(false);
          break;
        case PaymentProcessor.OTHER:
          setAutopaymentEnabled(false);
          break;
      }
    };

    watchAutoPaymentStatus();
  }, [processor]);

  const globalSettings = useAppSelector(getGlobalSettings);
  const loggedUserData = useAppSelector(getLoggedUserData);

  const companyRulesDocument = useMemo(
    () => globalSettings?.bookingDocuments.find(({ docName }) => docName === BookingDocumentsNames.RULES),
    [globalSettings?.bookingDocuments],
  );

  /* const isPrePaymentApplied = contractInfo.payForEntirePeriod;

  const toggleAutopayment = useCallback((): void => {
    setAutopaymentEnabled((prevState) => !prevState);
  }, []); */

  useEffect(() => {
    if (processor === PaymentProcessor.SEPA) {
      setAutopaymentEnabled(true);
    }

    setAutopaymentEnabled(false);
  }, [processor]);

  const options = [
    // TODO получать из отдельного запроса (бэк не готов)
    {
      label: (
        <div className="flex flex-col items-start justify-between w-full desktop:flex-row desktop:items-center">
          <span>{t('Credit/Debit card')}</span>
          <div className="flex flex-wrap items-center gap-2">
            <AmexIcon className="w-10 h-10" />
            <VisaIcon className="w-10 h-10" />
            <MastercardIcon className="w-10 h-10" />
            <AppleIcon className="w-7 h-7" />
            <GoogleIcon className="w-7 h-7" />
          </div>
        </div>
      ),
      value: PaymentProcessor.STRIPE,
      content: (
        // <div>
        //   <div className="font-semibold text-lg">{t('Auto pay')}</div>
        //   <div className="mb-2">
        //     {t('Turn on the automatic payment feature so you don`t miss payments. You can turn it off at any time!')}
        //   </div>
        //   <Checkbox name="isAutopayment" checked={isAutopaymentEnabled} onChange={toggleAutopayment} disabled={isPrePaymentApplied}>
        //     {t('Allow autopayment')}
        //   </Checkbox>
        // </div>

        <div className="mb-2">
          {t('We accept all major credit and debit cards, including Visa, Mastercard, American Express, GooglePay and ApplePay.')} <br />
        </div>
      ),
    },
    {
      label: t('Bank transfer'),
      value: PaymentProcessor.BANK_TRANSFER,
      content: t(
        'You will find the invoice for payment in your personal account. Please note that you cannot use the box until you have paid the first bill. Please pay your first bill before your move-in date',
      ),
    },
    {
      label: t('Other'),
      value: PaymentProcessor.OTHER,
      content: t(
        'You will find the invoice for payment in your personal account. Please note that you cannot use the box until you have paid the first bill. Please pay your first bill before your move-in date',
      ),
    },
  ];

  const toggleTermsAgreement = useCallback((): void => {
    setTermsAgreement((prevState) => !prevState);
  }, []);

  const handleOpenGratitudeModal = useCallback((): void => {
    openGratitudeModal({
      description: t(
        'Our company values each and every customer. If you have any questions or feedback, please don`t hesitate to reach out!',
      ),
      type: GratitudeModalType.RESERVATION,
      buttonId: 'reservation id', // TODO ADD ID FROM MARCUS !!!
    });
  }, [openGratitudeModal, t]);

  const onReservationSuccess = useCallback(() => {
    navigate(AppRoutes.MY_BOXES);

    handleOpenGratitudeModal();
  }, [handleOpenGratitudeModal, navigate]);

  return (
    <div className="space-y-6">
      <div className="bg-secondaryLight rounded-lg p-6">
        <PriceDetailsTable boxInfo={boxInfo} contractInfo={contractInfo} />
      </div>
      <div className="bg-secondaryLight rounded-lg p-6">
        <div ref={scrollRef} className="font-semibold text-3xl mb-6">
          {t('Select payment method')}
        </div>
        <Form>
          <div className="mb-4">
            <CollapseRadioButtonGroup options={options} selectedOption={processor} setSelectedOption={setProcessor} />
          </div>
          <Checkbox name="termsAgreement" requiredMark checked={termsAgreement} onChange={toggleTermsAgreement}>
            {t('I have read the following additions to the contract and accept them in full:')}
          </Checkbox>
          <div className="flex flex-col mt-2 mb-6">
            {companyRulesDocument && (
              <Link
                key={companyRulesDocument.link}
                className="text-accent text-sm font-normal"
                to={companyRulesDocument.link}
                target="_blank"
              >
                {t(companyRulesDocument.docName)}
              </Link>
            )}
          </div>

          <div className="flex space-x-3">
            <ReserveBox
              action={
                <Button
                  containerClassName="w-full"
                  size="large"
                  theme="secondary"
                  isDisabled={!termsAgreement}
                  description={
                    <Trans
                      t={t}
                      i18nKey="Reserve for <0>3 days free</0>. <1>No credit card needed!</1>"
                      components={[
                        <span key="0" className="text-accent text-xs font-medium" />,
                        <div key="1" className="text-xs font-normal" />,
                      ]}
                    />
                  }
                >
                  {t('Reserve')}
                </Button>
              }
              boxId={boxInfo?.boxId}
              contractOptions={contractInfo}
              warehouseId={warehouseInfo?.warehouseId}
              onSuccess={onReservationSuccess}
              userId={loggedUserData?.userId}
            />

            <BookAndPayButton
              isDisabled={!termsAgreement}
              loggedUserData={loggedUserData}
              contractInfo={contractInfo}
              boxInfo={boxInfo}
              warehouseInfo={warehouseInfo}
              globalSettings={globalSettings}
              isAutopaymentEnabled={isAutopaymentEnabled}
              processor={processor}
              onCompleteStep={onCompleteStep}
            />
          </div>
        </Form>
      </div>
    </div>
  );
});

import { useTranslation } from 'react-i18next';

type TranslationNameSpace =
  | 'booking'
  | 'header'
  | 'login-page'
  | 'common'
  | 'city'
  | 'user'
  | 'my-boxes'
  | 'contracts'
  | 'checkout'
  | 'payment'
  | 'warehouses'
  | 'boxes'
  | 'invoices'
  | 'reservations'
  | 'profile';

export const useAppTranslation = (nameSpace?: TranslationNameSpace | TranslationNameSpace[]): ReturnType<typeof useTranslation> => {
  return useTranslation(nameSpace);
};

import React, { FC, memo, useEffect, useState } from 'react';
import { Select } from 'antd';
import { ReactComponent as SelectArrow } from 'shared/assets/icons/SelectArrow.svg';
import { appLanguages } from '../constants/constants';
import i18n from 'app/config/i18Config/i18n';
import { AppLanguage } from 'app/config/i18Config/types';
import { APP_LANGUAGE_KEY } from 'shared/utils/constants';

export const LangSwitcher: FC = memo(() => {
  const [language, setLanguage] = useState<AppLanguage>(AppLanguage.en);

  const handleLanguageChange = async (language: AppLanguage): Promise<void> => {
    await i18n.changeLanguage(language);

    localStorage.setItem(APP_LANGUAGE_KEY, language);

    setLanguage(language);
  };

  useEffect(() => {
    const language = localStorage.getItem(APP_LANGUAGE_KEY) as AppLanguage;

    if (Object.values(AppLanguage).includes(language)) {
      setLanguage(language);
    }
  }, []);

  const bindDropdownToContainer = (): HTMLElement => document.getElementById('container') as HTMLElement;

  return (
    <div id="container">
      <Select
        options={appLanguages}
        value={language}
        size="large"
        suffixIcon={<SelectArrow />}
        getPopupContainer={bindDropdownToContainer}
        onChange={handleLanguageChange}
      />
    </div>
  );
});

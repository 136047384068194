import React, { FC, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { useGetWarehousesForBookingQuery, WarehouseForBooking } from 'entities/Warehouse';
import { Map, Marker } from 'features/Map';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { GeolocationCoords } from 'app/types/common';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Trans } from 'react-i18next';
import { Button } from 'shared/ui/Button';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { useGetCurrencySymbol } from 'app/appState';
import { getOutlinedCard } from '../model/selectors/getOutlinedCard';
import { getSearch } from '../model/selectors/getSearch';
import { getSelectedCard } from '../model/selectors/getSelectedCard';
import { warehouseMapActions } from '../model/slice/warehouseMapSlice';
import { useMapRoute } from '../utils/hooks/useMapRoute';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/CloseIcon.svg';
import { SelectWarehouseMapMobile } from './SelectWarehouseMapMobile';
import { WarehousesList } from './WarehousesList';

interface SelectWarehouseMapProps {
  onCompleteStep: (warehouse: WarehouseForBooking) => void;
}

export const SelectWarehouseMap: FC<SelectWarehouseMapProps> = memo((props) => {
  const { onCompleteStep } = props;

  const { t } = useAppTranslation(['booking', 'common']);
  const coords = useGeolocation();
  const { directionResult, calculateRoute, resetRoute } = useMapRoute();
  const currencySymbol = useGetCurrencySymbol();

  const dispatch = useAppDispatch();

  const [center, setCenter] = useState<GeolocationCoords>(coords);

  const selectedWarehouseCard = useAppSelector(getSelectedCard);
  const outlinedWarehouseCard = useAppSelector(getOutlinedCard);
  const search = useAppSelector(getSearch);
  const { data } = useGetWarehousesForBookingQuery();

  const selectedWarehouseData = data?.find((warehouse) => warehouse.warehouseId === selectedWarehouseCard);

  const { isDesktop } = useScreenBreakpoints();

  const onSelectWarehouseCard = useCallback(
    (warehouseCoords: GeolocationCoords): void => {
      // Add small offset to make warehouse card not overlap the marker in mobile version
      const verticalOffset = 0.003;

      setCenter(!isDesktop ? { ...warehouseCoords, lat: warehouseCoords.lat - verticalOffset } : warehouseCoords);
    },
    [isDesktop],
  );

  const warehousesWithCoordinates = useMemo(() => data?.filter((warehouse) => warehouse.longitude && warehouse.latitude), [data]);

  const drawRoute = useCallback(
    async (destination: GeolocationCoords, warehouseId: string): Promise<void> => {
      await calculateRoute(destination);
      dispatch(warehouseMapActions.setRoutedWarehouseId(warehouseId));
    },
    [calculateRoute, dispatch],
  );

  const clearRoute = useCallback((): void => {
    resetRoute();
    dispatch(warehouseMapActions.setRoutedWarehouseId(null));
  }, [dispatch, resetRoute]);

  const filteredBySearch = useMemo<WarehouseForBooking[] | undefined>(() => {
    return search
      ? warehousesWithCoordinates?.filter(({ name, cityName }) => {
          const searchValue = search.toLowerCase();
          const searchByName = name.toLowerCase().includes(searchValue);
          const searchByCity = cityName.toLowerCase().includes(searchValue);

          return searchByName || searchByCity;
        })
      : warehousesWithCoordinates;
  }, [warehousesWithCoordinates, search]);

  const markers = useMemo<Marker[]>(() => {
    return (
      filteredBySearch?.map((warehouse) => {
        const getLabelDescription = (): ReactNode => {
          const onBookNow = (): void => {
            onCompleteStep(warehouse);
          };

          return (
            <>
              <Trans
                t={t}
                i18nKey="From <0>{{minPrice}}{{currencySymbol}}</0>"
                components={[<span key="0" className="text-accent" />]}
                values={{ minPrice: warehouse.minPrice, currencySymbol }}
              />
              <Button className="mt-3.5" onClick={onBookNow}>
                {t('View boxes')}
              </Button>
            </>
          );
        };

        return {
          id: warehouse.warehouseId,
          position: { lat: warehouse.latitude, lng: warehouse.longitude },
          labelHeading: warehouse.name,
          labelDescription: getLabelDescription(),
        };
      }) || []
    );
  }, [currencySymbol, filteredBySearch, onCompleteStep, t]);

  const selectMarker = useCallback(
    (lat: number, lng: number, markerId: string): void => {
      dispatch(warehouseMapActions.setSelectedCard(markerId));
    },
    [dispatch],
  );

  return (
    <div className="absolute top-[calc(theme(spacing.headerHeight)+20px)] left-0 bottom-0 right-0 desktop:static">
      <div className="desktop:hidden">
        <SelectWarehouseMapMobile
          selectedWarehouseId={selectedWarehouseCard}
          outlinedWarehouseId={outlinedWarehouseCard}
          selectMarker={selectMarker}
          onGetRoute={drawRoute}
          onClearRoute={clearRoute}
          selectedWarehouseData={selectedWarehouseData}
          onSelectMarker={onSelectWarehouseCard}
          warehouses={filteredBySearch}
          selectedMarkerId={selectedWarehouseCard}
          markers={markers}
          center={center}
          directionResult={directionResult}
          onCompleteStep={onCompleteStep}
        />
      </div>
      <div className="hidden absolute top-[calc(theme(spacing.headerHeight)+34px)] left-0 right-0 bottom-0 desktop:block">
        <Map
          mapContainerClassName="w-full h-[calc(100vh-theme(spacing.headerHeight)-34px)]"
          markers={markers}
          center={center}
          mapId="warehouseMap"
          selectedMarkerId={selectedWarehouseCard}
          selectMarker={selectMarker}
          directionResult={directionResult}
          onSelectMarker={onSelectWarehouseCard}
          withMarkerPopups
        >
          <div className="absolute top-0 left-0 bottom-0 bg-secondaryLight p-6 w-[30%] min-w-[545px] overflow-y-auto">
            <WarehousesList
              warehouses={filteredBySearch}
              outlinedWarehouseId={outlinedWarehouseCard}
              onSelectCard={selectMarker}
              onGetRoute={drawRoute}
              onClearRoute={clearRoute}
              onSelectWarehouse={onCompleteStep}
            />
          </div>
          {directionResult && (
            <Button
              containerClassName="absolute top-2 right-1/4 -translate-x-1/2"
              theme="secondary"
              icon={<CloseIcon className="stroke-primaryLight" />}
              iconPosition="prev"
              onClick={clearRoute}
            >
              {t('Cancel', { ns: 'common' })}
            </Button>
          )}
          `
        </Map>
      </div>
    </div>
  );
});
